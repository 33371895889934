<template>
<div class="page-resumen-ventas-empleado">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Resumen de Ventas por Empleado" class="elevation-1 px-5 py-3">

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
              <v-row>
                <v-col sm="5" md="5" lg="5">
                    <v-autocomplete
                            v-model="model.id_empleado" :items="empleados" :hide-no-data="true"
                            :loading="isLoading"
                            :search-input.sync="search_empleado"
                            placeholder="Ingrese nombre del empleado para buscar"
                            prepend-icon="search" @change="setNombreEmpleado()"
                            item-text="nombre" item-value="name"
                            :rules="[rules.required]"
                            clearable
                            label="Empleado">
                        </v-autocomplete>
                </v-col>              
                <v-col sm="3" md="3" lg="3">
                    <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model="model.fecha_de" label="Fecha" color="secondary" prepend-icon="event"
                            @blur="model.fecha_de = parse_date(model.fecha_de)" :rules="[rules.required, valida_fecha]">
                            </v-text-field>
                        </template>

                        <v-date-picker v-model="model.fecha_de" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                            <v-spacer />

                            <v-btn color="secondary" x-small @click="menu1 = false">
                            Cancelar
                            </v-btn>
                        </v-date-picker>
                        </v-menu>
                </v-col>                     
              </v-row>
              <v-row>
                <v-col sm="5" md="5" lg="5">
                    <v-autocomplete
                            v-model="model.sucursal" :items="sucursales" :hide-no-data="true"
                            placeholder="Selecione una sucursal"
                            item-text="nombre" item-value="_id"
                            :rules="[rules.required]"
                            label="Sucursal">
                        </v-autocomplete>
                </v-col>
                <v-col sm="3" md="3" lg="3">
                    <v-btn color="success" @click.native="buscar()">
                        <v-icon>done</v-icon> Buscar Ventas
                    </v-btn>
                </v-col>
            </v-row>
              <v-row>                    
                <v-col sm="5" md="5" lg="5">
                    <v-autocomplete
                            v-model="model.serie" :items="series_empleado" :hide-no-data="true"
                            :loading="isLoadingSeries"
                            placeholder="Selecione una o más series"
                            small-chips deletable-chips
                            item-text="val" item-value="val"
                            multiple                        
                            label="Serie">
                        </v-autocomplete>                    
                </v-col>
                <v-col sm="2" md="2" lg="2">
                    <v-btn color="info" @click.native="filtrar_series()">
                        Filtrar Series
                    </v-btn>
                </v-col>
                <!--v-col sm="2" md="2" lg="2">
                    <v-text-field label="Del Folio" v-model="model.serie_de" :rules="[validaCantidadEntera]"></v-text-field>
                </v-col>
                <v-col sm="2" md="2" lg="2">
                    <v-text-field label="Al Folio" v-model="model.serie_a" :rules="[validaCantidadEntera, validaSerie]"></v-text-field>
                </v-col-->
              
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      
      <v-card-text>
        <div style="text-align:center"><h5>{{ nombreEmpleado }}</h5></div>
        <v-row>
            <v-col sm="12" lg="12" md="12">
                <h5>Ventas</h5>
                <div id="tableEfectivo" style="height: 40vh; overflow-y: scroll; overflow-x: scroll;">
                    <v-client-table :data="efectivo_items" :columns="columns" :options="options">
                        <template slot="noResults">
                            <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                        </template>
                        <template slot="subtotal" slot-scope="props">
                            <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.subtotal).toFixed(2))}}</div>
                        </template>
                        <template slot="impuestos" slot-scope="props">
                            <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.impuestos).toFixed(2))}}</div>
                        </template>
                        <template slot="total" slot-scope="props">
                            <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.total).toFixed(2))}}</div>
                        </template>
                        <template slot="efectivo" slot-scope="props">
                            <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.efectivo).toFixed(2))}}</div>
                        </template>
                        <template slot="voucher" slot-scope="props">
                            <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.voucher).toFixed(2))}}</div>
                        </template>
                        <template slot="transferencia" slot-scope="props">
                            <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.transferencia).toFixed(2))}}</div>
                        </template>
                        <template slot="cheques" slot-scope="props">
                            <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.cheques).toFixed(2))}}</div>
                        </template>
                    </v-client-table>
                </div>
                <div style="text-align:right !important;">
                    <table border="0" width="100%">
                        <tr style="font-size:16px; font-weight:bold;">
                            <td width="85%">Efectivo:</td>
                            <td>$ {{ formatNumber(parseFloat(total_efectivo).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>Voucher:</td>
                            <td>$ {{ formatNumber(parseFloat(total_voucher).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>Transferencias:</td>                            
                            <td>$ {{ formatNumber(parseFloat(total_transferencia).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>Cheques:</td>                            
                            <td>$ {{ formatNumber(parseFloat(total_cheques).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>TOTAL:</td>
                            <td>$ {{ formatNumber(parseFloat(total).toFixed(2))}}</td>
                        </tr>
                    </table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12" lg="12" md="12">
                <h5>Cortes de Caja</h5>
                <div id="tableCortes" style="height: 40vh; overflow-y: scroll; overflow-x: scroll;">
                    <v-client-table :data="cortes_items" :columns="columnsCorte" :options="optionsCorte">
                        <template slot="noResults">
                            <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                        </template>
                        <template slot="efectivo_retirado" slot-scope="props">
                            <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.efectivo_retirado).toFixed(2))}}</div>
                        </template>
                        <template slot="monto_voucher" slot-scope="props">
                            <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_voucher).toFixed(2))}}</div>
                        </template>
                        <template slot="monto_transferencia" slot-scope="props">
                            <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_transferencia).toFixed(2))}}</div>
                        </template>
                        <template slot="monto_cheques" slot-scope="props">
                            <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_cheques).toFixed(2))}}</div>
                        </template>
                        <template slot="monto_total" slot-scope="props">
                            <div style="text-align:right !important;">${{ formatNumber(parseFloat(props.row.monto_total).toFixed(2))}}</div>
                        </template>
                    </v-client-table>
                </div>
                <div style="text-align:right !important;">
                    <table border="0" width="100%">
                        <tr style="font-size:16px; font-weight:bold;">
                            <td width="85%">Efectivo Retirado:</td>                            
                            <td>$ {{ formatNumber(parseFloat(retirado_efectivo).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>Voucher Retirado:</td>
                            <td>$ {{ formatNumber(parseFloat(retirado_voucher).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>Transferencia Retirado:</td>                            
                            <td>$ {{ formatNumber(parseFloat(retirado_transferencia).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>Cheques Retirado:</td>                            
                            <td>$ {{ formatNumber(parseFloat(retirado_cheques).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:16px; font-weight:bold;">
                            <td>TOTAL RETIRADO:</td>
                            <td>$ {{ formatNumber(parseFloat(retirado_total).toFixed(2))}}</td>
                        </tr>
                    </table>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <hr style="height: 1px !important; background: #333;">
        </v-row>

        <v-row>

            <v-col sm="12" lg="12" md="12">
                <div style="text-align:right !important; color:red;">
                    <table border="0" width="100%">
                        <tr style="font-size:18px; font-weight:bold;">
                            <td width="85%">Restante en Caja<br>Efectivo:</td>
                            <td><br>$ {{ formatNumber(parseFloat(restante_efectivo).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:18px; font-weight:bold;">
                            <td>Voucher:</td>
                            <td>$ {{ formatNumber(parseFloat(restante_voucher).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:18px; font-weight:bold;">
                            <td>Transferencias:</td>
                            <td>$ {{ formatNumber(parseFloat(restante_transferencia).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:18px; font-weight:bold;">
                            <td>Cheques:</td>
                            <td>$ {{ formatNumber(parseFloat(restante_cheques).toFixed(2))}}</td>
                        </tr>
                        <tr style="font-size:18px; font-weight:bold;">
                            <td>TOTAL:</td>                            
                            <td>$ {{ formatNumber(parseFloat(restante_total).toFixed(2))}}</td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <v-btn dark v-show="parseFloat(restante_total)>0" color="blue" @click.native="showCorte()">
                                    <v-icon small>mdi-package-down</v-icon> Corte General ${{formatNumber(parseFloat(restante_total).toFixed(2))}}
                                </v-btn>
                            </td>                            
                        </tr>
                    </table>                
                </div>                
            </v-col>
        </v-row>

      </v-card-text>


        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

    <v-dialog v-model="modal_corte" max-width="55%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">edit</v-icon>&nbsp;
          <span class="subheading">
            <strong>Realizar Corte General</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_corte = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formcorte" lazy-validation>
          <v-container>
            <v-row>
                <v-col>
                    EMPLEADO: <strong>{{model_corte.cajero}}</strong>
                </v-col>
                <v-col>
                    FECHA: <strong>{{parse_date(model_corte.fecha)}}</strong>
                </v-col>
                <v-col>
                    SUCURSAL: <strong>{{ model_corte.sucursal!=undefined?model_corte.sucursal.nombre:""}}</strong>
                </v-col>
            </v-row><br>
            <v-row >
              <v-col sm="4" md="4" lg="4" >
                <v-text-field label="$ A Retirar" v-model="model_corte.monto_total" readonly disabled></v-text-field>
              </v-col>
              <v-col sm="4" md="4" lg="4" >
                <v-text-field label="$ Faltante" v-model="model_corte.faltante" :rules="[validaCantidadFloatCero]"></v-text-field>
              </v-col>
              <v-col sm="4" md="4" lg="4" >
                <v-text-field label="$ Sobrante" v-model="model_corte.sobrante" :rules="[validaCantidadFloatCero]"></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col sm="8" md="8" lg="8" style="text-align:center !important;">
                <v-text-field label="Observaciones" v-model="model_corte.observaciones"></v-text-field>
              </v-col>
            </v-row>            
            <v-row justify="center">
              &nbsp;
            </v-row>
            <v-row justify="center">
              <v-btn @click.native="modal_corte = false">
                <v-icon>cancel</v-icon> Cancelar
              </v-btn>
              &nbsp;&nbsp;
              <v-btn color="success" @click.native="saveCorteGeneral()">
                <v-icon>done</v-icon> Guardar Corte
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

    </v-card>
  </v-dialog>

</div>
</template>

<script>

export default {
  components: {
  },
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },
    /*search_empleado(val) {
        //Si no ha escrito 2 letras o mas
        if (val == null || val.length<2 ) return
        // Si todavía esta buscando
        if (this.isLoading) return
        this.isLoading = true
        let data = {
                    "selector": {
                        "type":"user",
                        "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                        "estatus": "Activo"
                    },
                    // "sort": ["_id"],
                };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/_users/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        this.empleados = response.data.docs;
                    }else
                        this.empleados = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los empleados.",
                        footer: ""
                    });
                }).then(()=>{ this.isLoading=false; });
      },*/


  },
  mounted: function() {
    if (!this.verificaPermiso('t.cortes.empleado.resumen')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    var today = window.moment().format("YYYY-MM-DD");
    this.model.fecha_de = today;
    this.get_sucursales();
    this.get_empleados();
  },
  data() {
    return {
        series_empleado:[],        
        modal_corte:false,
        model_corte:{},        
        nombreEmpleado:"",
        total_efectivo:0,
        total_voucher:0,
        total_transferencia:0,
        total_cheques:0,
        total:0,
        retirado_efectivo:0,
        retirado_voucher:0,
        retirado_transferencia:0,
        retirado_cheques:0,
        retirado_total:0, 
        restante_efectivo:0, 
        restante_voucher:0, 
        restante_transferencia:0,
        restante_cheques:0,
        restante_total:0,         
        efectivo_items:[],
        cortes_items: [],
        columns: ["sucursal.nombre","caja","folio","fecha","subtotal","impuestos","total","efectivo","voucher","transferencia","cheques","observaciones"],
        options:{
            headings:{
                "sucursal.nombre":"Sucursal",
                "caja":"Caja Cobro"
            },
        },
        columnsCorte: ["sucursal.nombre","caja","fecha","supervisor.nombre","efectivo_retirado","monto_voucher","monto_transferencia","monto_cheques","monto_total"],
        optionsCorte:{
            headings:{
                "caja":"Caja Corte",
                "sucursal.nombre":"Sucursal",
                "supervisor.nombre":"Retiró",
                "efectivo_retirado":"Efectivo retirado",
                "monto_voucher":"Voucher retirado",
                "monto_transferencia":"Transferencias",
                "monto_cheques":"Cheques",
                "monto_total":"Total retirado"
            },
        },
        sucursales:[],
        menu1:false,
        menu2:false,
        menu3:false,
        menu4:false,
      search_empleado:'',
      search_marca: null,
      tipos_precios: [],
      categorias:[],
      marcas:[],
      empleados:[],
      articulos_proveedores:[],
      fab: [],
      isLoading: false,
      isLoadingSeries: false,
      articulos_encontrados: [],
      id_cliente: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Cortes",
          disabled: true,
          href: ""
        },
        {
          text: "Resumen de Ventas Por Empleado",
          disabled: true,
          href: ""
        }
      ],

      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: []
    }
  },
  methods: {
    get_sucursal: function(id_sucursal){
        var filtro = this.sucursales.find(e => e._id == id_sucursal);
        if(filtro){
            return filtro;
        } else{
            return false;
        } 

    },
    get_empleados: function() {
        this.isLoading = true;
        let data = {
                    "selector": {
                        "type":"user",                        
                        "estatus": "Activo"
                    },
                    // "sort": ["_id"],
                };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/_users/_find/',data)
            .then(response => {
                if (response.data.docs.length > 0){
                    this.empleados = response.data.docs.sort(this.ordenar_nombre);
                }else
                    this.empleados = [];
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener los empleados.",
                    footer: ""
                });
            }).then(()=>{ this.isLoading=false; });
    },
      ordenar_nombre: function( a, b ){
            if ( a.nombre.toLowerCase() < b.nombre.toLowerCase()){
                return -1;
            }
            if ( a.nombre.toLowerCase() > b.nombre.toLowerCase()){
                return 1;
            }
            return 0;
        },
    showCorte: function(){
        this.model_corte = this.clean_model_corte();
        this.model_corte.cajero = this.nombreEmpleado;
        this.model_corte.cajero_id = this.model.id_empleado;
        this.model_corte.sucursal = this.get_sucursal(this.model.sucursal);
        var fecha_seleccionada = this.model.fecha_de + window.moment().format("THH:mm:ss");
        this.model_corte.fecha =  fecha_seleccionada; //fecha del día seleccionado
        this.model_corte.fecha_corte_general = window.moment().format("YYYY-MM-DDTHH:mm:ss");
        //console.log("CORTE: ", this.model_corte.fecha);
        this.model_corte.supervisor = {"email": this.$local_storage.get("sb_usuario"), "nombre":this.$local_storage.get("sb_nombre_usuario")}
        this.model_corte.monto_monedas =  parseFloat(parseFloat(this.restante_efectivo).toFixed(2));//Efectivo restante
        this.model_corte.efectivo_caja =  this.model_corte.monto_monedas;//Efectivo restante
        this.model_corte.efectivo_retirado =  this.model_corte.monto_monedas;//Efectivo restante
        this.model_corte.monto_voucher =  parseFloat(parseFloat(this.restante_voucher).toFixed(2));//voucher restante
        this.model_corte.monto_transferencia =  parseFloat(parseFloat(this.restante_transferencia).toFixed(2));//transferencia restante
        this.model_corte.monto_cheques =  parseFloat(parseFloat(this.restante_cheques).toFixed(2));//cheques restante
        this.model_corte.monto_total = parseFloat(parseFloat(this.restante_total).toFixed(2));//restante total

        this.modal_corte = true;
    },
    saveCorteGeneral:function(){
        if(this.$refs.formcorte.validate()){
            
            window.dialogLoader.show('Espere un momento por favor..');

            if(this.model_corte.faltante == null || this.model_corte.faltante.toString().trim() ==""){
                this.model_corte.faltante = 0;    
            } else {
                this.model_corte.faltante = parseFloat(parseFloat(this.model_corte.faltante).toFixed(2));
            }
            
            if(this.model_corte.sobrante == null || this.model_corte.sobrante.toString().trim() ==""){
                this.model_corte.sobrante = 0;    
            } else {
                this.model_corte.sobrante = parseFloat(parseFloat(this.model_corte.sobrante).toFixed(2));
            }
            
            var self = this;
            window.axios.create({
                withCredentials: true
              })
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', this.model_corte)
            .then(async response => {
                
                /* Se guardaba el id_corte en las ventas, pero ya no es necesario ya que se filtra por sucursal las ventas y los cortes */
                /*await Promise.all(self.efectivo_items.map(async (val) => {
                    var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_ventas/_update/set_id_corte/" + val._id;
                    var data = {
                        id_corte: response.data.id
                    };

                    await window.axios
                        .post(url, data)
                        .then(responseUpdate => {
                        
                        }).catch(error => {
                            console.log(error);                        
                        });
                }));*/

                this.modal_corte = false;
                window.dialogLoader.hide();
                window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                    color: 'success'
                });
                this.buscar(this.model.serie);
                
            })
            .catch(error => {
                window.dialogLoader.hide();
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al guardar el registro. Intente nuevamente",
                    footer: ""
                });
            });
            
        }
    },
    setNombreEmpleado: function(){
        var self = this;
        var filtro = this.empleados.find(function(e){
            return e.name == self.model.id_empleado;
        });

        if(filtro)
            self.nombreEmpleado = filtro.nombre;
        else
            self.nombreEmpleado = "";
    },
    validaCantidadFloatCero: function (value) {
        const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
        if (value != null && value != "" && !pattern.test(value)) {
            return "Cantidad no válida"
        } else if (value != null && value != "") {
            if (parseFloat(value) <= 0)
                return true;
        }
        return true;
    },
    validaSerie:function(){
        var self = this;
        if(self.model.serie_de.toString().trim() != "" && self.model.serie_a.toString().trim() != ""){
            if( parseInt(self.model.serie_de) > parseInt(self.model.serie_a))
                return "No debe ser mayor que folio inicial";
        }
        return true;
    },
    filtrar_series:function(){
        //console.log("Series: ", this.model.serie);
        this.buscar(this.model.serie);
    },
    
    buscar: function (series_filtro = []) {
        if(this.$refs.form.validate()){
            try {
                window.dialogLoader.show('Espere un momento por favor..');
                var self = this;

                let data = {
                    "selector": {
                        "type":"ventas",
                        "caja":{"$exists":true},
                        //"estatus": {"$in":["Finalizada","Facturada","Factura Global"]},
                        "sucursal._id": self.model.sucursal,
                        "fecha": {
                            "$gte": self.model.fecha_de,
                            "$lte": self.model.fecha_de+"T23:59:59.999"
                        },
                        'usuario_id':self.model.id_empleado
                    },
                    "fields": [
                        "_id","caja", "folio", "fecha","subtotal","impuestos","total","pago","cambio","sucursal"
                    ],
                    "use_index":"_design/93c72e287dccc1c43609554b59c0d7b503195d03"
                };                

                
                if(series_filtro.length > 0){//Se desactivo fltro de series para poner el de sucursal //Hasta que lo revise Alvaro haber si le funciona de esta manera
                    data["selector"]["caja"] = {"$in": series_filtro};
                } else {
                    this.series_empleado = [];
                    self.model.serie = [];
                    self.isLoadingSeries = true;
                }

                window.axios
                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                    .then(response => {
                        this.efectivo_items = [];
                        self.cortes_items = [];
                        this.total_efectivo = 0;
                        this.total_voucher = 0;
                        this.total_transferencia = 0;
                        this.total_cheques = 0;
                        this.total = 0;
                        this.retirado_efectivo = 0;
                        this.retirado_voucher = 0;
                        this.retirado_transferencia = 0;
                        this.retirado_cheques = 0;
                        this.retirado_total = 0;
                        this.restante_efectivo = 0;
                        this.restante_voucher = 0;
                        this.restante_transferencia = 0;
                        this.restante_cheques = 0;
                        this.restante_total = 0;
                        
                        if (response.data.docs.length > 0){
                            response.data.docs.sort(function (a, b) {
                                //Ordenar por fecha más reciente
                                return new Date(b.fecha) - new Date(a.fecha);
                            });
                            this.efectivo_items = response.data.docs;
                            //Calculos
                            for(var k in this.efectivo_items){
                                
                                var x = this.efectivo_items[k];

                                if(series_filtro.length == 0){
                                    this.series_empleado.push({"val":x.caja});
                                }

                                x["fecha"] = self.parse_date_time_mx(x["fecha"]);
                                var efectivo = 0;
                                var voucher = 0;
                                var transferencia = 0;
                                var cheques = 0;
                                for(var kp in x.pago){
                                    x["observaciones"] = x.pago[kp].observaciones;
                                    if(x.pago[kp].metodo.toString().toLowerCase().indexOf("efectivo") != -1){
                                        efectivo += parseFloat(x.pago[kp].cantidad);
                                    } else if(x.pago[kp].metodo.toString().toLowerCase().indexOf("cheque") != -1){
                                        cheques += parseFloat(x.pago[kp].cantidad);
                                    } else if(x.pago[kp].metodo.toString().toLowerCase().indexOf("transferencia") != -1){
                                        transferencia += parseFloat(x.pago[kp].cantidad);
                                    } else {
                                        voucher += parseFloat(x.pago[kp].cantidad);
                                    }
                                }
                                x["efectivo"] = efectivo - x.cambio; //Al pago de efectivo se resta el cambio
                                x["voucher"] = voucher;
                                x["transferencia"] = transferencia;
                                x["cheques"] = cheques;
                                self.total_efectivo += efectivo - x.cambio;
                                self.total_voucher += voucher;
                                self.total_transferencia += transferencia;
                                self.total_cheques += cheques;
                                self.total += efectivo - x.cambio;
                                self.total += voucher;
                                self.total += transferencia;
                                self.total += cheques;
                            }

                            //CORTES DE CAJA del empleado
                            let dataCorte = {
                                "selector": {
                                    "type":"corte_caja",
                                    "sucursal._id": self.model.sucursal,
                                    "fecha": {
                                        "$gte": self.model.fecha_de,
                                        "$lte": self.model.fecha_de+"T23:59:59.999"
                                    },                                    
                                    'cajero_id':self.model.id_empleado
                                },
                                "use_index":"_design/f6dd575c11d46f31e457d7be1338d29661924b3e"
                            };

                            window.axios
                            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', dataCorte)
                            .then(responseCorte => {
                                if (responseCorte.data.docs.length > 0){
                                    
                                    responseCorte.data.docs.sort(function (a, b) {
                                        //Ordenar por fecha más reciente
                                        return new Date(b.fecha) - new Date(a.fecha);
                                    });
                                    self.cortes_items = responseCorte.data.docs;
                                    for(var k in self.cortes_items){
                                        var corte = self.cortes_items[k];
                                        corte["fecha"] = self.parse_date_time_mx(corte["fecha"]);
                                        self.retirado_efectivo += parseFloat(corte["efectivo_retirado"]);
                                        self.retirado_voucher += parseFloat(corte["monto_voucher"]);
                                        self.retirado_transferencia += parseFloat(corte["monto_transferencia"]);
                                        self.retirado_cheques += parseFloat(corte["monto_cheques"]);
                                        self.retirado_total += parseFloat(corte["monto_total"]);
                                    }
                                } else {
                                    
                                    self.cortes_items = [];
                                }
                                self.restante_efectivo = self.total_efectivo - self.retirado_efectivo;
                                self.restante_voucher = self.total_voucher - self.retirado_voucher;
                                self.restante_transferencia = self.total_transferencia - self.retirado_transferencia;
                                self.restante_cheques = self.total_cheques - self.retirado_cheques;
                                self.restante_total = self.total - self.retirado_total;
                            }).catch(error => {
                                self.cortes_items = [];
                                console.log("Error: ",error);
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al consultar cortes de caja. Intente nuevamente",
                                    footer: ""
                                });
                            }).then(()=>{
                                window.dialogLoader.hide();
                            });


                        } else {                            
                            window.dialogLoader.hide();
                        }
                    }).catch(error => {
                        window.dialogLoader.hide();
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al realizar la búsqueda. Intente nuevamente",
                            footer: ""
                        });
                    }).then(()=>{
                        self.isLoadingSeries = false;
                    });
            } catch (error) {
                console.log("ERROR al buscar: ",error);
            }
        }
    },
    parse_date_time_mx(date) {
      if (!date) return null;
      return window.moment(String(date)).format("DD-MM-YYYY HH:mm");
    },
    set_id_corte:function(doc, req) { 
        if (doc) { 
            var datos = JSON.parse(req['body']); 
            if (doc['id_corte'] != undefined && doc['id_corte'] == "") { 
                doc['id_corte'] = datos['id_corte'];                 
            } 
            return [doc, 'true']; 
        } else { 
            return [null, 'No se encontró la venta'] 
        } 
    },
    //"set_id_corte":"function(doc, req) { if (doc) { var datos = JSON.parse(req['body']); if (doc['id_corte'] != undefined && doc['id_corte'] == '') { doc['id_corte'] = datos['id_corte']; } return [doc, 'true']; } else { return [null, 'No se encontró la venta'] } }",
    formatNumber: function (x) {
      if (x == undefined) return 0;
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    },
    get_sucursales: function () {
        let data = {
            "selector": {
                "type":"sucursales",
                "estatus": "Activo"
            },
            "fields": [
                "nombre", "_id","nombre","clave"
            ]
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                this.sucursales = [];                
                if (response.data.docs.length > 0){
                    this.sucursales = response.data.docs;                    
                }
                    
            })

            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las sucursales.",
                    footer: ""
                });
            });
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    valida_time: function(time) {
      if(time!=null && time.toString()!=""){
            if(time.toString().length != 5)
                return "Hora no valida1";
            if(!time.match(/^\d{2}:(?:[0-5]\d)$/))
                return "Hora no valida2";
            var time_arr = time.split(":");
            if(time_arr.length!=2){
                return "Hora no valida3";
            }else{
                if(isNaN(time_arr[0]) || isNaN(time_arr[1])){
                    return "Hora no valida4";
                }
                if(parseInt(time_arr[0])<24 && parseInt(time_arr[1])<60)
                {
                    return true;
                } else{
                    return "Hora no valida5";
                }
            }
      }
      return true;

    },
    validaCantidadEntera: function(value) {
      const pattern = /^[\d]*$/;
      if (value != null && value != "" && !pattern.test(value)) {
        return "Número no válido"
      } else
        return true;
    },
    clean_model: function() {
      return {        
        fecha_de: '',        
        id_empleado: '',
        canceladas: "0",
        serie:[],
        sucursal:"",
        serie_de:"",
        serie_a:"",
      };
    },
    clean_model_corte: function() {
      return {        
        "type": "corte_caja",
        "tipo": "corte_general",
        "caja": "GENERAL", //caja de la ultima venta
        "cajero": "", //nombre del cajero
        "cajero_id": "", //email del cajero
        "sucursal": {}, 
        "fecha": "", //Fecha del día seleccionado
        "fecha_corte_general":"", 
        "supervisor": "", //usuario logueado ["_id", "nombre", "email"]
        
        "billetes_1000": 0,
        "monto_1000": 0,
        "billetes_500": 0,
        "monto_500": 0,
        "billetes_200": 0,
        "monto_200": 0,
        "billetes_100": 0,
        "monto_100": 0,
        "billetes_50": 0,
        "monto_50": 0,
        "billetes_20": 0,
        "monto_20": 0,
        "monto_monedas": 0,
        "billetes_total": 0,
        
        "efectivo_caja": 0, 
        "efectivo_retirado": 0,
        "restante_en_caja": 0, //restante efectivo

        "cantidad_voucher": 0,
        "monto_voucher": 0, //monto voucher retirado
        "cantidad_transferencia": 0,
        "monto_transferencia": 0,//monto transferencias retirado
        "cantidad_cheques": 0,
        "monto_cheques": 0,//monto cheques retirado
        "monto_total": 0,  //monto total retirado

        //Exclusivos del corte general
        "faltante":0,
        "sobrante":0,
        "observaciones":"",
      };
    },
    generar_reporte: function(){
        if(this.$refs.form.validate()){
        try {
                window.dialogLoader.show('Espere un momento por favor..');

                window.axios
                    .post(process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_ventas_empleados/", this.model, {
                        "Content-type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Allow": "POST",
                        "cache-control": "no-cache",
                        "responseType": "arraybuffer"
                    })
                    .then(response => {
                        var bytes = response.data;
                        let blob = new Blob([bytes], {
                                type: response.headers["content-type"]
                            }),
                            url = window.URL.createObjectURL(blob);

                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = "Ventas Empleados.xlsx";
                        a.click();
                        a.parentNode.removeChild(a);
                    })
                    .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al generar el reporte. Intente nuevamente.",
                        footer: ""
                    });
                    }).then(()=>{
                    window.dialogLoader.hide();
                    });
            } catch (error) {
            console.log("ERRORS: ",error);
            }
        }
    },



  }
}
</script>
